// GLOBAL VARIABLES
var tetloose = tetloose || {},
    $window = $(window),
    $document = $(document),
    $html = $('html'),
    $body = $('body'),
    $cloneFrom = $('[rel=js-clone--from]'),
    $cloneTo = $('[rel=js-clone--to]'),
    $mobileNavTrigger = $('[rel=js-mobile-nav-toggle]'),
    $carousel = $('.carousel'),
    $small = '480',
    $medium = '768',
    $large = '960',
    $xlarge = '1024',
    $modal = $('[rel=js-modal]'),
    $modalTrigger = $('[rel=js-modal-open]');


// BROWSER CLASSES
tetloose.browserClasses = function () {
  // FIREFOX
  Modernizr.addTest('firefox', function () {
    if (!!navigator.userAgent.match(/firefox/i)) {
      $html.addClass('firefox no-touch');
      $html.removeClass('touch');
    }
  });

  // IE10
  /*@cc_on
    if (/^10/.test(@_jscript_version)) {
      $html.addClass('ie10');
    }
  @*/

  // IE11
  if(navigator.userAgent.match(/Trident.*rv:11\./)) {
    $html.addClass('ie11');
  }
};


// NAV TOGGLE
tetloose.openMobileNav = function (elm) {
  elm.on('click', function(e) {
    e.preventDefault();

    if ($body.hasClass('nav-is-open')) {

      $body.removeClass('nav-is-open');

      return
    }

    $body.addClass('nav-is-open');
  });
};


// MOBILE MENU
tetloose.clone = function (elm, cloneTo) {

  elm.clone(true, true).appendTo(cloneTo);

  cloneTo.find('nav').attr('rel', '');
  cloneTo.find('nav').removeClass('nav');

};


// FAST CLICK
tetloose.fastClick = function () {
  FastClick.attach(document.body);
};


// SLICK SLIDER
tetloose.slickSlider = function () {
  if ($carousel) {
    $carousel.each(function() {
      var t = $(this),
          action = t.attr('rel');
      if (action === 'js-carousel--portfolio') {
        t.slick( {
          speed: 500,
          autoplaySpeed: 10000,
          slidesToShow: 4,
          infinite: false,
          autoplay: true,
          appendArrows: t.find('.carousel-pagination'),
          prevArrow: t.parent().find('.carousel-pagination [rel=js-carousel-pagination-next]'),
          nextArrow: t.parent().find('.carousel-pagination [rel=js-carousel-pagination-prev]'),
          draggable: true,
          adaptiveHeight: true,
          responsive: [
            {
              breakpoint: $large,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: $medium,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: $small,
              settings: {
                slidesToShow: 1
              }
            }
          ]

        });
      } else {
        t.slick( {
          speed: 500,
          autoplaySpeed: 7000,
          slidesToShow: 1,
          infinite: true,
          autoplay: true,
          arrows: false,
          draggable: false,
          adaptiveHeight: true
        });
      }
    });
  }
};




// MODAL
tetloose.modal = function () {

  var $modalContent = $modal.find('.modal-content'),
      $modalClose = $modal.find('[rel=js-modal-close]');

  $modalTrigger.on('click', function(e) {
    e.preventDefault();

    console.log('click')

    tetloose.buildModal($(this));
  });

  $modalContent.on('click', function(e) {
    e.stopPropagation();
  });

  $modalClose.on('click', function(e) {
    e.preventDefault();

    tetloose.closeModal();
  });

  $modal.on('click', function(e) {
    tetloose.closeModal();
  });

};

tetloose.buildModal = function (elm) {
  var $configID = $(elm).data('configid'),
      $embed = $('[rel=js-modal-embed]');

  $embed.html('<div data-configid="'+$configID+'" style="width:525px; height:263px;" class="issuuembed"></div><script type="text/javascript" src="//e.issuu.com/embed.js" async="true"></script>');

  $modal.fadeIn('slow');
};

tetloose.closeModal = function () {
  $modal.fadeOut('fast');
  $('[rel=js-modal-embed]').html('');
};


tetloose.killOverlay = function () {
  $('.site-is-loading').fadeOut('slow');


};


// INIT FUNCTIONS
tetloose.init = function () {

  $('html *').imagesLoaded().done( function( instance ) {
    tetloose.browserClasses();
    tetloose.openMobileNav($mobileNavTrigger);
    tetloose.clone($cloneFrom, $cloneTo);
    tetloose.fastClick();
    tetloose.slickSlider();
    tetloose.modal();
    tetloose.killOverlay();
  }).fail( function() {

  });



};


// DOC READY
$document.on('ready', function() {
  "use strict";
  tetloose.init();
});


// DOC RESIZE
$window.on('resize', function () {
  "use strict";

  $body.removeClass('nav-is-open');
});
